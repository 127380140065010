<!-- If you want to use this component, include it with parent component ContentImageTeaser -->
<template>
  <div class="bg-teasers">
    <template
      v-for="(item, index) in teaserElements.slice(0,5)"
    >
      <router-link-simple
        v-if="item.href"
        :key="`contentimageteaser_collage_${index}`"
        v-lazy:background-image="item.img"
        :to="item.href"
        :target="item.target"
        :class="`bg-teaser ${(index === position) ? 'span-row-2' : 'size-16-9'} ${item.teaser}`"
        :title="$t(item.title)"
      >
        <div
          v-if="item.logo"
          class="logo"
        >
          <img
            :data-src="item.logo"
            :alt="$t(item.title)"
            class="lazyload"
            data-expand="200"
          >
        </div>

        <component
          :is="item.headingType ? item.headingType : 'div'"
          class="title"
          :class="item.headingClass || ''"
        >
          {{ $t(item.title) }}
        </component>
      </router-link-simple>
      <div
        v-else
        :key="`contentimageteaser_collage_${index}`"
        v-lazy:background-image="item.img"
        :class="`bg-teaser ${(index === position) ? 'span-row-2' : 'size-16-9'} ${item.teaser}`"
      >
        <div
          v-if="item.logo"
          class="logo"
        >
          <img
            :data-src="item.logo"
            :alt="$t(item.title)"
            class="lazyload"
            data-expand="200"
          >
        </div>
        <div class="title">
          {{ $t(item.title) }}
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import RouterLinkSimple from 'components/RouterLinkSimple.vue';

export default {
  name: 'ContentTeaserCollage',
  components: {
    RouterLinkSimple,
  },
  props: {
    teaserElements: {
      type: Array,
      default: () => [],
    },
    // position can by left, center, right
    teaserPosition: {
      type: String,
      default: 'center',
    },
  },
  computed: {
    position() {
      if (this.teaserPosition === 'left') {
        return 0;
      } if (this.teaserPosition === 'right') {
        return 2;
      }
      return 1;
    },
  }
};
</script>
