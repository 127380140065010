<!-- If u add new teaser, add them as new component in folder teaser and import them here -->
<template>
  <div class="container content-image-teasers">
    <h2
      v-if="teaserTitle"
      :class="teaserTitleClass + ' text-center'"
    >
      {{ teaserTitle }}
    </h2>
    <component
      :is="teaserComponent"
      :teaser-elements="teaserElements"
      :show-button="showButton"
      :show-body="showBody"
      :slice-count="sliceCount"
      :classname="classname"
      :teaser-position="teaserPosition"
    />
  </div>
</template>

<script>
import 'lazysizes';

// Components of folder teaser
import ContentTeaserGrid from 'components/teaser/ContentTeaserGrid.vue';
import ContentTeaserCollage from 'components/teaser/ContentTeaserCollage.vue';
import ContentTeaserPortrait from 'components/teaser/ContentTeaserPortrait.vue';
import ContentTeaserLandscape from 'components/teaser/ContentTeaserLandscape.vue';
import ContentTeaserWide from 'components/teaser/ContentTeaserWide.vue';
import ContentTeaserOnlytext from 'components/teaser/ContentTeaserOnlytext.vue';
import ContentTeaserImageandtext from 'components/teaser/ContentTeaserImageandtext.vue';
import ContentTeaserImageandtextnolink from 'components/teaser/ContentTeaserImageandtextnolink.vue';
import ContentTeaserLandscapecontent from 'components/teaser/ContentTeaserLandscapecontent.vue';

export default {
  name: 'ContentImageTeaser',
  components: {
    ContentTeaserGrid,
    ContentTeaserPortrait,
    ContentTeaserCollage,
    ContentTeaserLandscape,
    ContentTeaserWide,
    ContentTeaserOnlytext,
    ContentTeaserImageandtext,
    ContentTeaserImageandtextnolink,
    ContentTeaserLandscapecontent,
  },
  props: {
    teaserTitle: {
      type: String,
      default: '',
    },
    teaserTitleClass: {
      type: String,
      default: 'h3',
    },
    teaserElements: {
      type: Array,
      default: () => [],
    },
    // position can by left, center, right
    teaserPosition: {
      type: String,
      default: 'center',
    },
    // type of teaser portrait, landscape, collage
    teaserType: {
      type: String,
      default: 'landscape',
    },
    showButton: {
      type: Boolean,
      default: true,
    },
    showBody: {
      type: Boolean,
      default: true,
    },
    sliceCount: {
      type: Number,
      default: 6,
    },
    classname: {
      type: String,
      default: 'cols-4',
    },
  },
  computed: {
    teaserComponent() {
      const capitalizedType = this.teaserType.charAt(0).toUpperCase() + this.teaserType.slice(1);
      return `ContentTeaser${capitalizedType}`;
    }
  }
};
</script>
<style lang="scss" scoped>
  /deep/ .img-teasers {
    .img-teaser {
      height: 100%;
      margin: 0;

      .title{
        margin: 0;
      }

      .logo {
        display: block;
        position: absolute;
        right: $margin_content_xs;
        top: $margin_content_xs;
        text-align: right;
        max-width: 33%;

        &.logo-small {
          max-width: 20%;
        }

        img {
          max-width: 100%;
          display: block;
          height: auto;
        }
      }
    }

    .row {
      > * {
        margin-bottom: 30px;
      }
    }
  }

  /deep/ .bg-teasers {
    margin: 0 0 $margin_content;
    overflow: hidden;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: $margin_content;

    .browserIE & {
      @include floatCols(20px);
    }

    @media #{$media_sm} {
      grid-template-columns: repeat(2, 1fr);
    }
    @media #{$media_xs} {
      display: block;
    }

    &.cols-5 {
      grid-template-columns: repeat(5, 1fr);

      @media #{$media_sm} {
        grid-template-columns: 1fr;
      }
    }


    .bg-teaser {
      @include radius($radius_card);

      display: block;
      position: relative;
      background-position: center center;
      background-size: cover;
      background-repeat: no-repeat;
      margin: 0 !important;
      padding: 0;
      overflow: hidden;

      .browserIE & {
        @include floatCol(5, 20px, 66%);

        @media #{$media_md} {
          @include floatCol(3, 20px, 66%);
        }

        @media #{$media_sm} {
          @include floatCol(2, 20px, 66%);
        }

        @media #{$media_xs} {
          @include floatCol(1, 20px, 66%);
        }
      }

      &.size-16-9 {
        height: 0;
        padding: 0 0 56.25%;
        @media #{$media_xs} {
          @include collageTeaserMobile;
        }
      }

      &.size-3-2 {
        height: 0;
        padding: 0 0 66.66667%;
        @media #{$media_xs} {
          height: 0 !important;
          padding: 0 0 56% !important; /* EDVUE-2932: img dimension on xs 16:9 */
          margin: 0 0 $margin_content !important;
        }
      }

      &.size-2-3 {
        height: 0;
        padding: 0 0 150%;
        @media #{$media_xs} {
          height: 0 !important;
          padding: 0 0 56% !important; /* EDVUE-2932: img dimension on xs 16:9 */
          margin: 0 0 $margin_content !important;
        }
      }

      &.size-2-1 {
        height: 0;
        padding: 0 0 50%;
        @media #{$media_xs} {
          height: 0 !important;
          padding: 0 0 56% !important; /* EDVUE-2932: img dimension on xs 16:9 */
          margin: 0 0 $margin_content !important;
        }
      }

      &.span-row-2 {
        grid-row: span 2 / auto;
        @media #{$media_xs} {
        @include collageTeaserMobile;
        }
      }
    }
  }

  /deep/ .bg-teaser,
  /deep/ .grid-teaser {
    @include radius($radius_card);
    overflow:hidden;
    position: relative;
    .title {
        position: absolute;
        left: 0;
        line-height: 1.1em;
        bottom: 0;
        background: rgba(#000, 0.4);
        padding: 0.75em 0.5em;
        display: block;
        text-align: center;
        width: 100%;
        color: #fff;
        margin: 0;

        @include font_bold;
    }
  }

  /deep/ .logo {
    display: block;
    position: absolute;
    right: $margin_content_xs;
    top: $margin_content_xs;
    text-align: right;
    max-width: 33%;
    height: 100px;
    width: 100px;

    img {
      max-width: 100%;
      display: block;
      height: auto;
    }
  }
</style>
