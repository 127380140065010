<!-- If you want to use this component, include it with parent component ContentImageTeaser -->
<template>
  <div>
    <div
      v-for="(item, itemKey) in teaserElements.slice(0,1)"
      :key="`ContentImageTeaser_wide_${itemKey}`"
      v-lazy:background-image="item.img"
      class="teaser-wide"
    >
      <div class="text">
        <router-link-simple
          v-if="item.href"
          :to="item.href"
          :data-ident="`imageTeaserWide_${itemKey}`"
          :target="item.target"
          class="h3"
        >
          <component
            :is="item.headingType ? item.headingType : 'div'"
            class="title"
          >
            {{ $t(item.title) }}
          </component>
        </router-link-simple>
        <div
          v-else
          class="h3"
        >
          <component
            :is="item.headingType ? item.headingType : 'div'"
            :data-ident="`imageTeaserWideHead_${itemKey}`"
            class="title"
          >
            {{ $t(item.title) }}
          </component>
        </div>
      </div>
      <div class="options">
        <template
          v-for="(option, optionKey) in item.options"
        >
          <router-link-simple
            v-if="option.href"
            :key="`ContentImageTeaser_wide_options_${optionKey}`"
            :data-ident="`contentImageTeaserOptions_${optionKey}`"
            :to="option.href"
            :target="option.target"
            class="btn btn-primary"
          >
            <div v-dompurify-html="$t(option.html)" />
          </router-link-simple>
          <div
            v-else
            :key="`ContentImageTeaser_wide_options_${optionKey}`"
            :data-ident="`contentImageTeaserwideOptions_${optionKey}`"
            class="btn btn-primary"
          >
            <div v-dompurify-html="$t(option.html)" />
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import RouterLinkSimple from 'components/RouterLinkSimple.vue';

export default {
  name: 'ContentTeaserWide',
  components: {
    RouterLinkSimple,
  },
  i18nOptions: { namespaces: 'home' },
  props: {
    teaserElements: {
      type: Array,
      default: () => [],
    },
  },
};
</script>

<style lang="scss" scoped>
.teaser-wide {
  .title {
    margin: 0;
    font-size: inherit;
    color: inherit;
  }
}
</style>
