import { render, staticRenderFns } from "./ContentImageTeaser.vue?vue&type=template&id=2a352ad6&scoped=true"
import script from "./ContentImageTeaser.vue?vue&type=script&lang=js"
export * from "./ContentImageTeaser.vue?vue&type=script&lang=js"
function injectStyles (context) {
  
  var style0 = require("./ContentImageTeaser.vue?vue&type=style&index=0&id=2a352ad6&prod&lang=scss&scoped=true")
if (style0.__inject__) style0.__inject__(context)

}

/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  "2a352ad6",
  "39f7d5ed"
  
)

export default component.exports