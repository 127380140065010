<!-- If you want to use this component, include it with parent component ContentImageTeaser -->
<template>
  <div>
    <section
      class="grid-teasers"
      :class="classname"
    >
      <template
        v-for="item in teaserElements"
      >
        <router-link-simple
          :key="item.link.href"
          :title="$t(item.link.title)"
          class="grid-teaser"
          :class="{'hidden':(item.hide && !seen)}"
          :to="item.link.href"
        >
          <div
            v-if="item.logo"
            class="logo"
          >
            <img
              :data-src="item.logo"
              :alt="$t(item.title)"
              class="lazyload"
              data-expand="200"
            >
          </div>
          <img
            :src="item.img"
            :alt="$t(item.alt)"
            class="img-responsive"
          >
          <div class="title">
            {{ $t(item.title) }}
          </div>
        </router-link-simple>
      </template>
    </section>
    <div
      v-if="togglePresent"
      class="text-center mb-5"
    >
      <button
        class="btn btn-more"
        type="button"
        data-ident="toggleCollapse"
        @click="seen = !seen"
      >
        <i class="icon">
          <icon-chevron-up v-if="seen" />
          <icon-chevron-down v-else />
        </i>
        {{ showAll }}
      </button>
    </div>
  </div>
</template>

<script>
import RouterLinkSimple from 'components/RouterLinkSimple.vue';
import IconChevronDown from 'ICON:IconChevronDown';
import IconChevronUp from 'ICON:IconChevronUp';

export default {
  name: 'ContentTeaserGrid',
  components: {
    RouterLinkSimple,
    IconChevronUp,
    IconChevronDown,
  },
  props: {
    teaserTitle: {
      type: String,
      default: '',
    },
    teaserTitleClass: {
      type: String,
      default: 'h3',
    },
    teaserElements: {
      type: Array,
      default: () => [],
    },
    classname: {
      type: String,
      default: 'cols-4',
    },
  },
  data() {
    return {
      seen: false,
    };
  },
  computed: {
    showAll() {
      let returnString = this.$t('common:showMore');
      if (this.seen) {
        returnString = this.$t('common:showLess');
      }
      return returnString;
    },
    togglePresent() {
      // if there is one element with 'hide' given toggle is true
      const hideElement = this.teaserElements.find((element) => element.hide === true);
      return !!(hideElement);
    },
  }
};
</script>
