<!-- If you want to use this component, include it with parent component ContentImageTeaser -->
<template>
  <div class="img-teasers imageandtextnolink">
    <div class="row row-narrow">
      <div
        v-for="(item, index) in teaserElements.slice(0,6)"
        :key="item.href + '_' + index"
        :class="`col-12
                       col-md-6
                       col-lg-${(teaserElements.length === 4 || teaserElements.length === 2) ? '6' : '4'}`"
      >
        <div class="img-teaser">
          <div class="img-teaser-header">
            <div
              v-if="item.img"
              class="img"
            >
              <img
                :src="item.img"
                :alt="$t(item.title)"
                class="img-inner"
              >
            </div>
            <div
              v-if="item.logo"
              class="logo"
            >
              <img
                :data-src="item.logo"
                :alt="$t(item.title)"
                class="lazyload"
                data-expand="200"
              >
            </div>
            <div class="title">
              {{ $t(item.title) }}
            </div>
          </div>

          <div
            v-if="item.html || item.options"
            class="img-teaser-body"
          >
            <div
              v-dompurify-html="$t(item.html)"
            />
            <div
              v-if="item.options && item.options.length > 0"
              class="mt-3"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ContentTeaserImageandtextnolink',
  props: {
    teaserElements: {
      type: Array,
      default: () => [],
    },
  },
};
</script>
