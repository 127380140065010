<!-- If you want to use this component, include it with parent component ContentImageTeaser -->
<template>
  <div class="bg-teasers cols-4">
    <template
      v-for="item in teaserElements.slice(0,3)"
    >
      <!-- images 400x600 -->
      <router-link-simple
        v-if="item.href"
        :key="item.href"
        v-lazy:background-image="item.img"
        :to="item.href"
        class="bg-teaser size-2-3"
        :title="item.title"
        data-ident="imageTeaserPortraitHref"
      >
        <div class="title">
          {{ $t(item.title) }}
        </div>
      </router-link-simple>
      <div
        v-else
        :key="item.href"
        v-lazy:background-image="item.img"
        data-ident="imageTeaserNoLink"
        class="bg-teaser size-2-3"
      >
        <div class="title">
          {{ $t(item.title) }}
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import RouterLinkSimple from 'components/RouterLinkSimple.vue';

export default {
  name: 'ContentTeaserPortrait',
  components: {
    RouterLinkSimple,
  },
  props: {
    teaserElements: {
      type: Array,
      default: () => [],
    },
  },
};
</script>
