<!-- If you want to use this component, include it with parent component ContentImageTeaser -->
<template>
  <div class="img-teasers">
    <div class="row row-narrow">
      <div
        v-for="(item, index) in teaserElements.slice(0,6)"
        :key="item.href + '_' + index"
        class="col-md-4"
      >
        <div class="img-teaser p-3">
          <div v-dompurify-html="$t(item.html)" />

          <router-link-simple
            v-if="item.href"
            :to="item.href"
            :target="item.target"
            :data-ident="`contentImageTeaserText${index}`"
            class="btn btn-line btn-block"
          >
            {{ $t(item.title) }}
          </router-link-simple>

          <div
            v-else
            class="btn btn-line btn-block"
          >
            {{ $t(item.title) }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import RouterLinkSimple from 'components/RouterLinkSimple.vue';

export default {
  name: 'ContentTeaserOnlytext',
  components: {
    RouterLinkSimple,
  },
  props: {
    teaserElements: {
      type: Array,
      default: () => [],
    },
  },
};
</script>
