<!-- If you want to use this component, include it with parent component ContentImageTeaser -->
<template>
  <div class="content-teasers-landscape">
    <div
      v-for="(item, index) in teaserElements"
      :key="item.href + '_' + index"
      class="content-teaser-landscape"
    >
      <div class="row row-no-pad">
        <div class="col-sm-4 col-md-3">
          <router-link-simple
            v-if="item.href"
            :data-ident="`contentTeaserLandscape${index}`"
            :to="item.href"
            :target="item.target"
            class="img"
          >
            <img
              v-if="item.img"
              :src="item.img"
              :alt="$t(item.title)"
            >
          </router-link-simple>
          <div
            v-else
            class="img"
          >
            <img
              v-if="item.img"
              :src="item.img"
              :alt="$t(item.title)"
            >
          </div>
        </div>
        <div class="col-sm-8 col-md-9">
          <div class="text">
            <component
              :is="item.headingType ? item.headingType : 'h3'"
              class="heading h3"
            >
              {{ $t(item.title) }}
            </component>
            <div v-dompurify-html="$t(item.html)" />
            <div class="options mt-3">
              <template
                v-for="(option, index) in item.options"
              >
                <router-link-simple
                  v-if="option.href"
                  :key="option.href"
                  :to="option.href"
                  :target="option.target"
                  class="btn btn-primary"
                >
                  <div v-dompurify-html="$t(option.html)" />
                </router-link-simple>
                <div
                  v-else
                  :key="'option_div_' + index"
                  class="btn btn-primary"
                >
                  <div v-dompurify-html="$t(option.html)" />
                </div>
              </template>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import RouterLinkSimple from 'components/RouterLinkSimple.vue';

export default {
  name: 'ContentTeaserLandscapecontent',
  i18nOptions: { namespaces: 'home' },
  components: {
    RouterLinkSimple,
  },
  props: {
    teaserElements: {
      type: Array,
      default: () => [],
    },
  },
};
</script>
<style lang="scss" scoped>
  .content-teaser-landscape {
    .heading {
      margin-bottom: .75em;
    }

    a.img {
    display: block;
    }
  }
</style>
