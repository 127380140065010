<!-- If you want to use this component, include it with parent component ContentImageTeaser -->
<template>
  <div class="img-teasers">
    <div class="row row-narrow">
      <div
        v-for="(item,index) in teaserElements.slice(0,6)"
        :key="index"
        class="col-md-4"
      >
        <div class="img-teaser">
          <router-link-simple
            v-if="item.href"
            v-lazy:background-image="item.img"
            data-ident="imageTeaserLandscapeHref"
            :to="item.href"
            :target="item.target"
            class="img-teaser-header"
          >
            <div class="title">
              {{ $t(item.title) }}
            </div>
          </router-link-simple>
          <div
            v-else
            v-lazy:background-image="item.img"
            data-ident="imageBackgroundTeaser"
            class="img-teaser-header"
          >
            <div class="wtitle">
              {{ $t(item.title) }}
            </div>
          </div>
          <div
            v-if="item.localeskey"
            v-dompurify-html="$t(item.localeskey)"
            class="px-3 pt-3"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import RouterLinkSimple from 'components/RouterLinkSimple.vue';

export default {
  name: 'ContentTeaserLandscape',
  components: {
    RouterLinkSimple,
  },
  props: {
    teaserElements: {
      type: Array,
      default: () => [],
    },
  },
};
</script>
