<!-- If you want to use this component, include it with parent component ContentImageTeaser -->
<template>
  <div class="img-teasers">
    <div class="row row-narrow">
      <div
        v-for="(item, index) in teaserElements.slice(0,sliceCount)"
        :key="item.href + '_' + index"
        :class="`col-12
                       col-md-6
                       col-lg-${(teaserElements.length === 4 || teaserElements.length === 2) ? '6' : '4'}`"
      >
        <div class="img-teaser">
          <router-link-simple
            v-lazy:background-image="item.img"
            :to="item.href"
            :target="item.target"
            :data-ident="`contentImageAndText${index}`"
            :title="$t(item.title)"
            class="img-teaser-header"
            :class="item.hrefClass"
          >
            <div
              v-if="item.logo"
              class="logo"
              :class="item.logoClass"
            >
              <img
                :data-src="item.logo"
                :alt="$t(item.title)"
                class="lazyload"
                data-expand="200"
              >
            </div>
            <component
              :is="item.headingType ? item.headingType : 'div'"
              class="title"
              :class=" item.titleClass ? item.titleClass : ''"
            >
              {{ $t(item.title) }}
            </component>
          </router-link-simple>
          <div
            v-if="showBody"
            class="img-teaser-body d-flex flex-column"
          >
            <div
              v-if="typeof item.html === 'string'"
              v-dompurify-html="$t(item.html)"
            />
            <div v-else>
              <div
                v-for="(itemHtml, itemHtmlIndex) in item.html"

                :key="'itemHtml_' + itemHtmlIndex"
                v-dompurify-html="$t(itemHtml)"
              />
            </div>

            <div
              v-if="item.options && item.options.length > 0"
              class="mt-3"
            >
              <!-- additional links exists -->
              <p class="my-0">
                <router-link-simple
                  v-for="(option, option_index) in item.options"
                  :key="option.href + '_' + option_index"
                  :to="option.href"
                  :data-ident="`contentImageAndText${option_index}`"
                >
                  {{ $t(option.title) }}
                  <i class="icon">
                    <icon-chevron-right />
                  </i><br>
                </router-link-simple>
              </p>
            </div>
          </div>
          <div
            v-if="showButton"
            class="img-teaser-footer"
          >
            <router-link-simple
              :to="item.href"
              class="btn btn-primary"
              data-ident="imageTeaserLink1"
              :title="((item.linktitle) ? $t(item.linktitle) : $t(item.title))"
            >
              {{ (item.linktitle) ? $t(item.linktitle) : $t(item.title) }}
              <i class="icon">
                <icon-chevron-right />
              </i>
            </router-link-simple>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import RouterLinkSimple from 'components/RouterLinkSimple.vue';
import IconChevronRight from 'ICON:IconChevronRight';

export default {
  name: 'ContentTeaserImageandtext',
  components: {
    RouterLinkSimple,
    IconChevronRight,
  },
  props: {
    teaserElements: {
      type: Array,
      default: () => [],
    },
    showButton: {
      type: Boolean,
      default: true,
    },
    showBody: {
      type: Boolean,
      default: true,
    },
    sliceCount: {
      type: Number,
      default: 6,
    },
  },
};
</script>
