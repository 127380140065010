import { render, staticRenderFns } from "./ContentTeaserLandscapecontent.vue?vue&type=template&id=4e5dd8c5&scoped=true"
import script from "./ContentTeaserLandscapecontent.vue?vue&type=script&lang=js"
export * from "./ContentTeaserLandscapecontent.vue?vue&type=script&lang=js"
function injectStyles (context) {
  
  var style0 = require("./ContentTeaserLandscapecontent.vue?vue&type=style&index=0&id=4e5dd8c5&prod&lang=scss&scoped=true")
if (style0.__inject__) style0.__inject__(context)

}

/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  "4e5dd8c5",
  "769be27a"
  
)

export default component.exports